import React, { useState } from "react"
import WithLocation from "../components/WithLocation"
import Layout from "../components/Layout"
import { ValidatedInput } from "../elements/shared/inputs"
import {  SubmitButton } from "../elements/shared/buttons"
import styled from "styled-components"
import useValidation from "../helpers/useValidation"
import resetPasswordValidation from "../helpers/validations/resetPasswordValidation"
import AuthenticationService from "../web_service/AuthenticationService"
import Form from "../elements/shared/form/Form"
import {  LoadingOverlay } from "../elements/shared/wrappers"
import { navigate } from "gatsby"
import { IsBrowser } from "../helpers/utils"
import { GlobalActionsType, ResponseStatus } from "../utils/constants"
import { UseGlobalDispatchContext } from "../context/globalContext"
import ProfileHeader from "../components/ProfileHeader"
import ProcessLoading from "../components/ProcessLoading"
import { NarrowWrapper } from "../components/shared/NarrowWrapper"
import StorageService from "../helpers/StorageService";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const Container = styled.div``

const INITIAL_STATE = {
  password: "",
}

const INITIAL_RESPONSE = {
  success: true,
  hasSubmitted: true,
  message: "Type your new password to reset the old one.",
}

const ResetPassword = ({ search }) => {
  const { token } = search
  const [isProcessing, setProcessing] = useState(false)
  const [response, setResponse] = useState(null)
  const authService = AuthenticationService.getService()
  const metadata = StorageService.getService().getConfig()
  const dispatch = UseGlobalDispatchContext()

  const headerContent = {
    title: "Reset password",
    description: "Type your new password to reset the old one..",
  }

  const signOut = () => {
    authService.logout(() => {})
    dispatch({ type: GlobalActionsType.USER, userData: false })
  }

  const authenticateUser = () => {
    if (isProcessing) {
      return
    }
    setProcessing(true)
    const { password } = values

    const data = {
      Password: password,
      Token: token,
    }

    authService.resetPassword(
      data,
      res => {
        setResponse({
          status: ResponseStatus.Success,
          message:
            "The password has been reset successfully. You can now log in.",
        })
        signOut()
      },
      err => {
        if (err.response) {
          setResponse({
            status: ResponseStatus.Failure,
            message: err.response.data,
          })
        } else {
          setResponse({
            status: ResponseStatus.Failure,
            message: "Oops! Something went wrong... Please check your internet connection"
          })
        }
      }
    )
  }

  const {
    handleChange,
    handleBlur,
    errors,
    values,
    handleSubmit,
  } = useValidation(INITIAL_STATE, resetPasswordValidation, authenticateUser)

  if (!token) {
    if (IsBrowser) {
      navigate("/")
    }
    return null
  }

  const onClosePopup = () => {
    setProcessing(false)
    setResponse(null)

    if (response) {
      if (response.status === ResponseStatus.Success) {
        if (IsBrowser) {
          navigate("/auth")
        }
      }
    }
  }

  return (
    <Layout>
      <GoogleReCaptchaProvider reCaptchaKey={metadata.recaptcha_site_key}>

      {isProcessing && (
        <LoadingOverlay
          id={"loadingOverlay"}
          onClick={e => {
            if (e.target.parentElement.id === "loadingOverlay") {

              onClosePopup()
            }
          }}>
          <ProcessLoading
            id={"processLoading"}
            response={response}
            setResponse={setResponse}
            setProcessing={setProcessing}
            modelType={""}
            onClose={onClosePopup}
          />
        </LoadingOverlay>
      )}
      <NarrowWrapper isProcessing={isProcessing}>
        <div className={"container mx-auto "}>
          <Container>

            <ProfileHeader content={headerContent} />
            <Form onSubmit={handleSubmit}>
              <ValidatedInput
                onChange={handleChange}
                onBlur={handleBlur}
                name={"password"}
                value={values.password}
                placeholder={"Password"}
                message={errors.password}
                isSecure
              />
              <SubmitButton />
            </Form>
          </Container>
        </div>
      </NarrowWrapper>
      </GoogleReCaptchaProvider>
    </Layout>
  )
}

export default WithLocation(ResetPassword)
