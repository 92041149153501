export default function (values) {
  const errors = {};

  if (!values.password) {
    errors.password = 'Required';
  } else if (!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,50}$/i.test(values.password)) {
    errors.password = 'Invalid password (Must be at least 8 characters, 1 Uppercase, 1 Lowercase, 1 Digit and 1 special character.)';
  }

  return errors;
};
